import React from "react";
import "../components/layout";

class Counter extends React.Component {
  constructor() {
    super();
    this.state = { count: 0 };
  }

  render() {
    return (
      <div>
        <h1>Counter</h1>
        <p>current count: {this.state.count}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          plus
        </button>
        <button onClick={() => this.setState({ count: this.state.count - 1 })}>
          minus
        </button>
        {/*<iframe*/}
        {/*  src="https://giphy.com/embed/l2QDQ3Uh9vFSyt8Qg"*/}
        {/*  width="480"*/}
        {/*  height="293"*/}
        {/*  frameBorder="0"*/}
        {/*  class="giphy-embed"*/}
        {/*  allowFullScreen*/}
        {/*></iframe>*/}
        <p>
          <a href="https://giphy.com/gifs/l2QDQ3Uh9vFSyt8Qg">via GIPHY</a>
        </p>
      </div>
    );
  }
}

export default Counter;
