//https://www.emojione.com/emoji/v3
// import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import React from "react";
import { Link } from "gatsby";
import { animated, useSpring } from "react-spring";
//    {emojify('🏠',options)}
import "../../index.css";
import "./header.scss";
import "./article.css";
import "font-awesome/css/font-awesome.min.css";
import "./elements.css";

// const linkStyle = css({ float: `left`, color: `black`})
// const linkStyle2 = css({ color: `black`})

//https://www.react-spring.io/
export default ({ children }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <>
      <div className="flex flex-col min-h-screen mx-auto max-w-main">
        <div className="homeIcons">
          <Link to="/" className="homeIconsChild">
            Topaz Tee
          </Link>
          <Link to="/notes" className="homeIconsChild">
            Notes
          </Link>
          {/*<Link to="/#generativeArt" className="">*/}
          {/*  <i className="fa fa-paint-brush fa-2x" aria-hidden="true" />*/}
          {/*  Generative Art*/}
          {/*</Link>*/}
          <a
            target="_blank"
            href="https://twitter.com/topaz_tee"
            className="navbar-icon"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAgdJREFUWEftmC1PA0EQhq9NDYpABaUoEgghgEIRUASDJhDsOQwGheQ/IMDVQ9AYkPwAwocBS2kFECTJpSWZndnm5jLs7vUCcwk127vO7r7zdGZ27iqR8k9Fub7oX+Cw/5APwT5sUm+avd7aPnOG1WXn+2ymVGC9CcLimxfwprU69SsEZyarsO/zaw/gyQTLIjA6vQdy8dJYPpLoqBiUGNNE7umyB6azm1UYnQT1CSSP96+M0wuNfARZiHCCrbsPc2tvEYb+dRvG7u0IjGuHXwLBsgis7VyAB8nEaBhJgZwlxlE+dOBOZ3oZxsaBiXWqt9kYxA30CRTKihW6PpfyXcxqvg7FGs3eHjffzt/NiARr3U/zj52skCWrg2URGGH2xrvzKWJiDJEVZqM9eSRyZH/0mFrfm6B+geQX1UG8JqJOkjxLpWuMPfvz8UYqe1OBCBf8SFIrEM/ejOPcYzxhfIFZO7aOjb2zLU+C6gXmzWIXSoEcTUu8CaoVSK7wZoFlMZkFZ3Ng7GWzuOwCXSFGfaMr+ylrXbEXTPDvBPICTc+9rO3iAhPW3RRNbkCwNAKpQGNXwolIfWEoOd/YyxJUK5CXF+mo8+1K0I5nayg5uZtRK5CRpJizntPTnUDSSYzmBb4dcz7V6RM4IJR+7eas1IJBIDG+yk/vB9ULzMus0Hk+b1gL3TB0sW8ArqEwk582IgAAAABJRU5ErkJggg=="
              alt="Twitter"
            />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/topaz-t/"
            className="navbar-icon"
          >
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI3MiIgdmlld0JveD0iMCAwIDcyIDcyIiB3aWR0aD0iNzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNOCw3MiBMNjQsNzIgQzY4LjQxODI3OCw3MiA3Miw2OC40MTgyNzggNzIsNjQgTDcyLDggQzcyLDMuNTgxNzIyIDY4LjQxODI3OCwtOC4xMTYyNDUwMWUtMTYgNjQsMCBMOCwwIEMzLjU4MTcyMiw4LjExNjI0NTAxZS0xNiAtNS40MTA4MzAwMWUtMTYsMy41ODE3MjIgMCw4IEwwLDY0IEM1LjQxMDgzMDAxZS0xNiw2OC40MTgyNzggMy41ODE3MjIsNzIgOCw3MiBaIiBmaWxsPSIjMDA3RUJCIi8+PHBhdGggZD0iTTYyLDYyIEw1MS4zMTU2MjUsNjIgTDUxLjMxNTYyNSw0My44MDIxMTQ5IEM1MS4zMTU2MjUsMzguODEyNzU0MiA0OS40MTk3OTE3LDM2LjAyNDUzMjMgNDUuNDcwNzAzMSwzNi4wMjQ1MzIzIEM0MS4xNzQ2MDk0LDM2LjAyNDUzMjMgMzguOTMwMDc4MSwzOC45MjYxMTAzIDM4LjkzMDA3ODEsNDMuODAyMTE0OSBMMzguOTMwMDc4MSw2MiBMMjguNjMzMzMzMyw2MiBMMjguNjMzMzMzMywyNy4zMzMzMzMzIEwzOC45MzAwNzgxLDI3LjMzMzMzMzMgTDM4LjkzMDA3ODEsMzIuMDAyOTI4MyBDMzguOTMwMDc4MSwzMi4wMDI5MjgzIDQyLjAyNjA0MTcsMjYuMjc0MjE1MSA0OS4zODI1NTIxLDI2LjI3NDIxNTEgQzU2LjczNTY3NzEsMjYuMjc0MjE1MSA2MiwzMC43NjQ0NzA1IDYyLDQwLjA1MTIxMiBMNjIsNjIgWiBNMTYuMzQ5MzQ5LDIyLjc5NDAxMzMgQzEyLjg0MjA1NzMsMjIuNzk0MDEzMyAxMCwxOS45Mjk2NTY3IDEwLDE2LjM5NzAwNjcgQzEwLDEyLjg2NDM1NjYgMTIuODQyMDU3MywxMCAxNi4zNDkzNDksMTAgQzE5Ljg1NjY0MDYsMTAgMjIuNjk3MDA1MiwxMi44NjQzNTY2IDIyLjY5NzAwNTIsMTYuMzk3MDA2NyBDMjIuNjk3MDA1MiwxOS45Mjk2NTY3IDE5Ljg1NjY0MDYsMjIuNzk0MDEzMyAxNi4zNDkzNDksMjIuNzk0MDEzMyBaIE0xMS4wMzI1NTIxLDYyIEwyMS43Njk0MDEsNjIgTDIxLjc2OTQwMSwyNy4zMzMzMzMzIEwxMS4wMzI1NTIxLDI3LjMzMzMzMzMgTDExLjAzMjU1MjEsNjIgWiIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4="
              alt="LinkedIn"
            />
          </a>
          <a
            target="_blank"
            href="https://github.com/topaztee"
            className="navbar-icon"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAlFJREFUWEfVmLtKA0EUhg1iETD2ETtJbATFlHaxtLFSMAHB0sZOsPLSCGkkCj5BougrCNamWBMrQ6JdRDshEawk4pn9zcwwl911i9k0k90zc+afb8+cuaTG9L+hb0oZ6sRhMvZj6txZgSSsXNokOrX6JShhMBAelZ7gx9AP+VcRTJbAp3abRuJ5D1RixMAHe1Ccsh/ZvwzNStAlgcKnDUsmKEFdvUH/k0ydbkeIeZ5gMgViZP8lhPaZqUmlq8gEEyOQi41YYBYKS+RHBhA6BhXo3RKIvPV1dxSLMDhJFw/or0wyNEFnBWIN7jevBXKZxXXhedC6MZLV1Z/4YCuUTBJAsKJo82DiBILE7doyjfzq+Z3Kau1ESdBWP3aCtg5llbb6EQSO+0vdBvVVq7PY6zfZfhAdbs/PClpsBHX1RwKP/dm8QKXnPfr+vyn8uBhMuEBbErw/rAaKTZAb5cOYCDogEBLMn9omVGcPSw5+FDtq9wRqjpfhhO6W9wV4F+dbSpjpIou5fI5lg073RZi1cqNfgokRqPvsNIB8Li+NOFo0jsiJZw+dN56gmwKzjSERWjkrkUDuJsGPFUYu7p+879MSdF4glEPo9E6BXmFfhthDPZzKdIepoHZ532eKQbI5L5AbAcVktsGyT6Y8589eNutwN4ObB/lQFdZuI6m9m3FZIGBK+e8vb5EdpzEFAWpns9vuBXU5j49VtwRWeiwf7s2ob4UrPRaTMdiNN7VcP+KO2lmBEIZve/oqZqS3FnuurLISdrxH7bB2tJP9yPkw5brAHy6QY3VmdklZAAAAAElFTkSuQmCC"
              alt="GitHub"
            />
          </a>
          {/*<a*/}
          {/*  target="_blank"*/}
          {/*  href="https://www.linkedin.com/in/topaz-t/"*/}
          {/*  className=""*/}
          {/*>*/}
          {/*  <i className="fa fa-linkedin fa-2x" aria-hidden="true" />*/}
          {/*</a>*/}
        </div>
        <animated.div style={props}>{children}</animated.div>
      </div>
    </>
  );
};
